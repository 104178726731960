import { isApiError } from 'util/request';
import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { removeUnderscoreAndCapitalizeString } from 'util/removeUnderscoreAndCapitalizeString';
import { arrayToCommaString } from 'util/arrayToCommaString';
import { messageReactNative } from 'util/messageReactNative';
import { generateReport, printToNative } from 'util/generateReport';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import {
  useGetAssignableChecklistsQuery,
  useEnableCourseMutation,
  useGetIsCourseEnabledQuery,
  useGetOperatorsQuery,
  useGetTeamMembersQuery,
  useGetCourseReportQuery,
} from 'services/pathwayApi';
import GenericError from 'sharedComponents/app/GenericError';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
} from 'store/user/selectors';
import { withRoles } from 'sharedComponents/app/withRoles';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import { toast } from 'react-hot-toast';
import {
  addManagePlansCategoryFilter,
  clearManagePlansCheckboxFilters,
  clearManagePlansSearchFilter,
  loadMorePlans,
  removeManagePlansFilter,
  setManagePlans,
  setManagePlansSearchFilter,
  setManagePlansSort,
} from 'store/managePlansFilter/slice';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from 'store/managePlansFilter/selector';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import ClearFiltersHeader from 'components/ClearFiltersHeader/ClearFiltersHeader';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import { setHeader } from 'store/header/slice';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import ManagePlansPlanCard from 'components/PlanCard/PlanCards/ManagePlansCard';
import ManagePlansCompliancePlanCard from 'components/PlanCard/PlanCards/ManagePlansComplianceCard';
import PrintReportModal from 'sharedComponents/app/popups/PrintReportModal';
import { convertDateForReports } from '../../../util/date';
import { NoMessage } from './ManagePlanView';

const ManagePlansTab = () => {
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const currentFilters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const [showEnablePlanPopUp, setShowEnablePlanPopUp] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [planId, setPlanId] = useState('');
  const [triggerComplianceReportHandler, setTriggerComplianceReportHandler] =
    useState(false);
  const [statusReport, setStatusReport] = useState({
    courseId: '',
    courseName: '',
    foodSafetyImage: '',
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const { t } = useTranslation();
  const { data: operators } = useGetOperatorsQuery();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  const [enableCourse] = useEnableCourseMutation();
  const dispatch = useDispatch();

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectLocationsWithTrainerPermission,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const searchFilter = useSelector(selectSearchFilter);
  const { showing, total } = useSelector(selectPagination);

  const OperatorsOfLocationsWithAtLeastLeader = operators?.filter(
    operator =>
      arrayIntersect(operator?.locations, locationsWithAtLeastLeaderPermission)
        .length > 0,
  );
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastTrainer,
  );

  const { data: complianceStatusData, refetch: refetchIsCourseEnabled } =
    useGetIsCourseEnabledQuery(
      OperatorsOfLocationsWithAtLeastLeader?.[0]?.id ?? operators?.[0]?.adId,
      {
        skip: !operators?.length,
      },
    );

  const {
    data: unOrderedPlans,
    isFetching,
    error,
    refetch,
  } = useGetAssignableChecklistsQuery();

  const isTrainerOrLeaderOrOperator =
    !!locationsWithAtLeastTrainerPermission.length ||
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;

  const planIsLearnUponDocumentUuid =
    planId?.match(Constants.UUID_REGEX_MATCH)?.length > 0;

  const { data: statusReportData, isSuccess: isStatusReportSuccess } =
    useGetCourseReportQuery(
      {
        courseId: planId,
        location: selectedReportLocations,
      },
      {
        skip:
          !planId ||
          !isTrainerOrLeaderOrOperator ||
          !planIsLearnUponDocumentUuid ||
          !selectedReportLocations.length,
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: allTeamMembersData, isFetching: isFetchingTeamMembers } =
    useGetTeamMembersQuery(
      {
        locations: locationsWithAtLeastTrainer,
      },
      { refetchOnMountOrArgChange: true },
    );
  // Set Plans
  useEffect(() => {
    const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
      return {
        ...plan,
        planName: getNameFromLanguage(plan.checklist.name).toLowerCase(),
      };
    });
    dispatch(
      setManagePlans({
        plans: storePlansWithName?.concat(unOrderedPlans?.courses) ?? [],
      }),
    );
  }, [dispatch, unOrderedPlans]);

  // we want to call api on each page load, no caching
  useEffect(() => {
    refetch();
  }, [refetch]);

  // Check if compliance plan is enabled
  useEffect(() => {
    if (complianceStatusData && unOrderedPlans) {
      const compliancePlansWithStatus = unOrderedPlans?.courses
        ?.filter(plan => plan?.courseID)
        .map(course => ({
          ...course,
          enabled: complianceStatusData?.[course?.id]?.enabled ?? false,
        }));
      const plansWithoutCompliance = unOrderedPlans?.checklists?.filter(
        plan => !plan?.courseID,
      );

      dispatch(
        setManagePlans({
          plans: plansWithoutCompliance?.concat(compliancePlansWithStatus),
        }),
      );
    }
  }, [complianceStatusData, dispatch, unOrderedPlans]);

  // Status Report
  useEffect(() => {
    if (isStatusReportSuccess) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData]);

  useEffect(() => {
    dispatch(setHeader(t('TrainingPlans.tabPlans')));
  }, [dispatch, t]);

  const statusReportToPrint = [...statusReport?.userData]
    .sort((teamMemberA, teamMemberB) => {
      return teamMemberA.firstName.localeCompare(teamMemberB.firstName);
    })
    .map(report => {
      return {
        [Constants.REPORT_TABLE.NAME]: `${report.firstName} ${report.lastName}`,
        [Constants.REPORT_TABLE.DATE_OF_BIRTH]: report.birthDate
          ? report.birthDate
          : t('Generic.na'),
        [Constants.REPORT_TABLE.COMPLETION_DATE]: report.courseCompletionDate
          ? convertDateForReports(report.courseCompletionDate)
          : t('Generic.notInCompliance'),
        [Constants.REPORT_TABLE.EXPIRATION_DATE]: report?.certificate
          ?.expiration
          ? convertDateForReports(report.certificate.expiration)
          : t('Generic.na'),
      };
    });

  //eslint-disable-next-line
  const tableHead = [
    [
      Constants.REPORT_TABLE.NAME,
      Constants.REPORT_TABLE.COMPLETION_DATE,
      Constants.REPORT_TABLE.EXPIRATION_DATE,
    ],
  ];
  if (showDoBChecked) {
    tableHead?.[0]?.splice(1, 0, Constants.REPORT_TABLE.DATE_OF_BIRTH);
  }

  //eslint-disable-next-line
  const reportToGenerate = {
    category: t(
      mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
    ),
    foodSafetyImage: statusReport?.foodSafetyLetters?.[0]?.content,
    location: `${t('Generic.at')} ${
      !statusReport?.locations.length
        ? statusReport?.locations.toString()
        : arrayToCommaString(statusReport?.locations, t('Generic.and'))
    }`,
    name: removeUnderscoreAndCapitalizeString(statusReport?.courseName ?? ''),
    reportToPrint: statusReportToPrint.map(teamMember =>
      tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
    ),
    tableHead,
  };

  //eslint-disable-next-line
  const messageToSend = {
    category: reportToGenerate.category,
    location: reportToGenerate.location,
    name: reportToGenerate.name,
    reportToPrint: statusReportToPrint,
    tableHead,
  };

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastTrainer);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastTrainer, noLocationsSelected]);

  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastTrainer?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
      resetPlanState();
      handleCurrentFilteredLocations();
    }
  }, [
    handleCurrentFilteredLocations,
    locationsWithAtLeastTrainer?.length,
    messageToSend,
    reportToGenerate,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (!!statusReport.courseId && !!triggerComplianceReportHandler) {
      handlePrintReport();
      setTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    statusReport.courseId,
    triggerComplianceReportHandler,
  ]);

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const onCancel = () => {
    setShowEnablePlanPopUp(false);
  };

  const onContinue = () => {
    enableCourse({
      operatorId: operators[0]?.id,
      courseId,
    })
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('TrainingPlans.toastMessage.enabled', { courseName })}
          </ToastMessageBlock>
        ));
        refetch();
        refetchIsCourseEnabled();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
    setShowEnablePlanPopUp(false);
  };

  const onShowPopUp = (id, name) => {
    setCourseId(id);
    setCourseName(name);
    setShowEnablePlanPopUp(true);
  };

  const handleShowDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
    resetPlanState();
  };

  const onPrintReport = (id, plan) => {
    setPlanId(id);
    if (
      getNameFromLanguage(plan?.courseName).includes('Food Safety') ||
      locationsWithAtLeastTrainer?.length > 1
    ) {
      setShowChooseReportLocationsPopup(true);
    } else {
      // need to wait for setPlanDetails to populate the state :(
      setTriggerComplianceReportHandler(true);
    }
  };

  const resetPlanState = () => {
    setStatusReport({
      courseId: '',
      courseName: '',
      foodSafetyImage: '',
      locations: [''],
      timeStamp: '',
      userData: [],
    });
    setPlanId('');
  };

  const categoryLabels = {
    [Constants.PLAN_CATEGORIES.DEFAULT]: {
      translationString: t('TrainingPlans.planCategories.default'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.DEFAULT),
    },
    [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.backOfHouse'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.BACK_OF_HOUSE),
    },
    [Constants.PLAN_CATEGORIES.COMPLIANCE]: {
      translationString: t('TrainingPlans.planCategories.compliance'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.COMPLIANCE),
    },
    [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.frontOfHouse'),
      value: !!currentFilters.includes(
        Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE,
      ),
    },
    [Constants.PLAN_CATEGORIES.HOSPITALITY]: {
      translationString: t('TrainingPlans.planCategories.hospitality'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.HOSPITALITY),
    },
    [Constants.PLAN_CATEGORIES.LEADERSHIP]: {
      translationString: t('TrainingPlans.planCategories.leadership'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.LEADERSHIP),
    },
    [Constants.PLAN_CATEGORIES.ONBOARDING]: {
      translationString: t('TrainingPlans.planCategories.onboarding'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.ONBOARDING),
    },
  };

  const sortOptions = [
    {
      id: '1',
      translationString: t('TrainingPlans.filtering.newest'),
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '2',
      translationString: t('TrainingPlans.filtering.oldest'),
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '3',
      translationString: t('TrainingPlans.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '4',
      translationString: t('TrainingPlans.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ];

  return (
    <>
      <StyledContent>
        <SearchFilterHeader
          onChange={e =>
            dispatch(
              setManagePlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearManagePlansSearchFilter())}
          searchPlaceholder={t('TrainingPlans.filtering.searchManagePlans')}
          searchValue={searchFilter}
          title={t('TrainingPlans.tabManagePlans')}
        />
        {!!isSmAndDown && !isFetching && !isFetchingTeamMembers && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setManagePlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              filteredAndSortedPlans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
          >
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlanCardList>
          {!isSmAndDown && !isFetching && !isFetchingTeamMembers && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeManagePlansFilter({ filter: value }));
                  } else {
                    dispatch(addManagePlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
            </StickyFilterCard>
          )}
          <PlanCardsContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setManagePlansSort({ sort: option.value }));
              }}
              onClear={() => {
                dispatch(clearManagePlansCheckboxFilters());
              }}
              options={sortOptions}
              showClear={false}
              text={`${total ?? 0} ${t('TrainingPlans.filtering.plans')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />

            <ClearFiltersHeader
              aliases={categoryLabels}
              clearAllFilters={() => {
                dispatch(clearManagePlansCheckboxFilters());
              }}
              clearFilter={value => {
                dispatch(removeManagePlansFilter({ filter: value }));
              }}
              filters={currentFilters}
            />
            <LoadingOverlay isOpen={isFetching || isFetchingTeamMembers} />
            <PlanCardsList>
              {!filteredAndSortedPlans?.length &&
                !isFetching &&
                !currentFilters?.length &&
                !searchFilter && (
                  <NoMessage
                    message={
                      <Trans
                        i18nKey={'TrainingPlans.createPlanOnBuildPlanPage'}
                      />
                    }
                  />
                )}
              {(!!currentFilters?.length || !!searchFilter) && !total && (
                <NoMessage message={t('TrainingPlans.noPlansResults')} />
              )}
              {!!filteredAndSortedPlans?.length && !isFetching && (
                <ManagePlansCardList>
                  {filteredAndSortedPlans.map(plan =>
                    !!plan?.checklist ? (
                      <ManagePlansPlanCard
                        assignedUsers={plan?.assignedUsers}
                        completedUsers={plan?.completedUsers}
                        data-testid="TeamPlanCard"
                        key={plan.checklist.id}
                        plan={plan.checklist}
                      />
                    ) : (
                      <ManagePlansCompliancePlanCard
                        assignedUsers={plan?.assignedUsers}
                        completedUsers={plan?.completedUsers}
                        course={{
                          courseID: plan?.courseID,
                          courseName: plan?.courseName,
                          createdDate: plan?.createdDate,
                          enabled: plan?.enabled,
                          id: plan?.id,
                        }}
                        data-testid="ManagePlansPlanCard"
                        enrollments={plan?.enrollments}
                        key={plan?.courseID}
                        onPrintReport={id => {
                          onPrintReport(id, plan);
                        }}
                        onShowEnableCompliancePlan={onShowPopUp}
                        totalTeamMembers={
                          allTeamMembersData.filter(
                            (value, innerIndex, self) =>
                              innerIndex ===
                              self.findIndex(user => user.adId === value.adId),
                          ).length
                        }
                      />
                    ),
                  )}
                  <LoadMorePaginator
                    onClick={() => dispatch(loadMorePlans())}
                    showing={showing}
                    showingText={t('TrainingPlans.showingXOfYPlans', {
                      showing,
                      total,
                    })}
                    total={total}
                  />
                </ManagePlansCardList>
              )}
            </PlanCardsList>
          </PlanCardsContainer>
        </PlanCardList>
      </StyledContent>
      <ConfirmationModal
        bodyText={t('Settings.complianceEnable', {
          name: courseName,
        })}
        headerText={t('Generic.enablePlan')}
        isOpen={showEnablePlanPopUp}
        onClose={onCancel}
        primaryButtonHandler={onContinue}
        primaryButtonText={t('Button.continue')}
        secondaryButtonHandler={onCancel}
        secondaryButtonText={t('Button.cancel')}
      />
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        children={
          locationsWithAtLeastTrainer.length > 1
            ? locationsWithAtLeastTrainer.map((id, idx) => (
                <StyledCheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : null
        }
        handleShowDoBCheckbox={handleShowDoBCheckbox}
        headerText={t('Generic.printReport')}
        isFoodSafety={!!statusReport?.courseName?.includes('Food Safety')}
        isOpen={showChooseReportLocationsPopup}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations}
        showDoBChecked={showDoBChecked}
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const ManagePlansCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default withRoles(ManagePlansTab, [
  Constants.USER_PERMISSIONS.LEADER,
  Constants.USER_PERMISSIONS.OPERATOR,
  Constants.USER_PERMISSIONS.TRAINER,
]);
