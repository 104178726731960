import { isApiError } from 'util/request';
import Constants from 'constants/index';
import { pluralize } from 'util/language';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import first from 'lodash/first';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useAddSearchResultFeedbackMutation,
  useGetSearchResultsQuery,
} from 'services/pathwayApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import Bugsnag from '@bugsnag/browser';
import GenericError from 'sharedComponents/app/GenericError';
import { formatBugsnagErrorMessage } from 'bugsnag';
import DocumentHead from 'DocumentHead';
import { useState, useEffect } from 'react';
import theme from 'styles/theme';
import { getCurrentLanguage } from 'i18n/language';
import { useDispatch, useSelector } from 'react-redux';
import { scrollPosition } from 'store/scrollPosition/selectors';
import { selectUserCountry } from 'store/user/selectors';
import {
  reduxScrollPosition,
  resetState,
  setData,
  setId,
  setPageNumber,
  setQuery,
  setYPosition,
} from 'store/scrollPosition/slice';
import { Button, LoadingIndicator, Typography } from 'cfa-react-components';
import { useAmplitudeTrack } from 'amplitude/useAmplitude';
import { useGetSearchResultsQuery as xpAPIUseGetSearchResultsQuery } from 'services/xpApi';
import {
  selectSearchResults,
  selectResult,
  selectHasNextPage,
  selectNumFound,
  selectShowingResults,
  selectCurrentPage,
  selectPageSize,
  selectStoredQuery,
  selectQueryId,
} from 'store/search/selectors';
import {
  setQueryId,
  setStoredQuery,
  setSearchResults,
  setShowingResults,
  setResult,
  setHasNextPage,
  setNumFound,
  addSearchResults,
  resetSearchState,
  reduxSearch,
  setCurrentPage,
  setPageSize,
} from 'store/search/slice';
import SearchResultCard from './components/SearchResultCard';

const SearchResultsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryId: reduxSearch['queryId'] = useSelector(selectQueryId);
  const storedQuery: reduxSearch['query'] = useSelector(selectStoredQuery);
  const searchResults: reduxSearch['searchResults'] =
    useSelector(selectSearchResults);
  const result: reduxSearch['result'] = useSelector(selectResult);
  useSelector(selectSearchResults);
  const hasNextPage: reduxSearch['hasNextPage'] =
    useSelector(selectHasNextPage);
  const currentPage: reduxSearch['currentPage'] =
    useSelector(selectCurrentPage);
  const pageSize: reduxSearch['pageSize'] = useSelector(selectPageSize);
  useSelector(selectPageSize);
  const numFound: reduxSearch['numFound'] = useSelector(selectNumFound);
  const showingResults: reduxSearch['showingResults'] =
    useSelector(selectShowingResults);
  const position: reduxScrollPosition = useSelector(scrollPosition);
  const selectedCountry: { id: string } = useSelector(selectUserCountry);
  const dispatch = useDispatch();
  const [activeThumbsUp, setActiveThumbsUp] = useState<any[]>([]);
  const [activeThumbsDown, setActiveThumbsDown] = useState<any[]>([]);
  const useQuery = paramName =>
    new URLSearchParams(useLocation().search)?.get(paramName);
  const query = useQuery('query');
  const languageCode = getCurrentLanguage();
  const [misspellingSuggestion, setMisspellingSuggestion] = useState('');

  const maxResults = Constants.MAX_SEARCH_RESULTS;

  if (query !== storedQuery) {
    dispatch(resetSearchState());
    dispatch(setStoredQuery(query));
  }

  const {
    searchResultFeedback: featureFlagSearchResultFeedback,
    xpApi: xpApiFeatureFlag,
  } = useFlags();

  const {
    data: xpAPIResult,
    isFetching: xpAPIIsFetching,
    error: xpAPIError,
  } = xpAPIUseGetSearchResultsQuery(
    {
      country: selectedCountry.id,
      language: languageCode,
      pageNumber: currentPage,
      query: query ?? '',
    },
    {
      refetchOnMountOrArgChange: false,
      skip:
        !xpApiFeatureFlag || xpApiFeatureFlag === undefined || !!position.id,
    },
  );

  const {
    data: coreResult,
    isFetching: coreIsFetching,
    error: coreError,
  } = useGetSearchResultsQuery(
    {
      language: languageCode,
      page: 1,
      pageSize: pageSize,
      query,
    },
    {
      refetchOnMountOrArgChange: false,
      skip: xpApiFeatureFlag || xpApiFeatureFlag === undefined || !!position.id,
    },
  );

  const isFetching = xpAPIIsFetching || coreIsFetching;
  const error = xpAPIError ?? coreError;

  useEffect(() => {
    dispatch(setHasNextPage(searchResults?.length < numFound));
  }, [dispatch, numFound, searchResults?.length]);

  useEffect(() => {
    if (
      xpApiFeatureFlag &&
      !!xpAPIResult &&
      xpAPIResult?.results !== searchResults
    ) {
      if (
        xpAPIResult.page === currentPage &&
        currentPage > 1 &&
        xpAPIResult.queryId !== queryId
      ) {
        dispatch(setQueryId(xpAPIResult.queryId));
        dispatch(addSearchResults(xpAPIResult.results));
      } else {
        dispatch(setQueryId(xpAPIResult.queryId));
        dispatch(setSearchResults(xpAPIResult.results));
      }
      setMisspellingSuggestion(first(xpAPIResult.spellingSuggestions));
      dispatch(setResult(xpAPIResult));
    } else if (!xpApiFeatureFlag && !!coreResult) {
      dispatch(setSearchResults(coreResult.results));
      setMisspellingSuggestion(
        first(coreResult.spellCorrectedQueryResults)?.suggestedQueryText,
      );
      dispatch(setResult(coreResult));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xpAPIResult, coreResult]);

  useEffect(() => {
    if (result && (!!result.totalResults || !!result.numberFound)) {
      dispatch(
        setNumFound(
          xpApiFeatureFlag
            ? Math.min(result.totalResults, maxResults)
            : Math.min(result.numberFound, maxResults),
        ),
      );
    }
  }, [dispatch, maxResults, result, xpApiFeatureFlag]);

  useEffect(() => {
    if (!position.id) {
      const oldShowingResults = Math.min(
        Math.ceil(pageSize * currentPage),
        maxResults,
      );
      const xpApiShowingResults = Math.min(
        Math.ceil(Constants.DEFAULT_PAGING_SIZE * currentPage),
        maxResults,
      );
      const newShowingResults = !!xpApiFeatureFlag
        ? xpApiShowingResults
        : oldShowingResults;

      dispatch(
        setShowingResults(
          hasNextPage ? newShowingResults : numFound > 0 ? numFound : null,
        ),
      );
    }
  }, [
    currentPage,
    dispatch,
    hasNextPage,
    maxResults,
    numFound,
    pageSize,
    position.id,
    showingResults,
    xpApiFeatureFlag,
  ]);

  const track = useAmplitudeTrack();
  useEffect(
    () => {
      if (
        result?.status === 'fulfilled' &&
        showingResults &&
        (query?.length ?? 0) > 0
      ) {
        if (track !== null) {
          // ensure we have initialized gtag scripts
          track('search_result', {
            num_results: numFound ?? 0,
            query: query ?? 'empty',
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result?.status],
  );

  useEffect(() => {
    if (position.pageNumber) {
      fetchCustomPage(position.pageNumber);
    }
  });

  useEffect(() => {
    // dispatch scroll position to store on scroll updates
    const handleScroll = () => {
      dispatch(setYPosition(window.scrollY));
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch]);

  useEffect(() => {
    // scroll to the correct position on page load
    if (!isFetching) {
      window.scrollTo({
        behavior: 'instant',
        top: position.yPostition,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    // reset scroll data on query change, do not reset on position.id change
    if (query !== position.query) {
      dispatch(resetState());
      dispatch(setQuery(query));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!position.pageNumber) {
      dispatch(setPageNumber(1));
    }
  }, [position.pageNumber, dispatch]);

  const fetchNextPage = num => {
    if (!!xpApiFeatureFlag) {
      dispatch(setCurrentPage(num));
    } else {
      dispatch(setPageSize(num * 12 > 100 ? 100 : num * 12));
    }
  };

  const fetchCustomPage = num => {
    if (!!xpApiFeatureFlag) {
      dispatch(setCurrentPage(num));
    } else {
      dispatch(setPageSize(num * 12 > 100 ? 100 : num * 12));
    }
  };

  /* Leaving comment in the code as a reminder of how to handle mutation
      with trigger (addSearchResultFeedback) and response (addResult)
  const [addSearchResultFeedback, addResult] = useAddSearchResultFeedbackMutation();
 useEffect(() => {
   console.log('result', result, 'addResult', addResult);
   if (addResult.error)  console.log(addResult.error);
  }, [result, addResult]);  */
  const [addSearchResultFeedback] = useAddSearchResultFeedbackMutation();
  const onSearchResultFeedback = ({ resultId, resultQueryId }) => {
    if (!resultQueryId || !resultId) {
      Bugsnag.notify(
        new Error(
          `No ${
            !resultQueryId ? 'queryId' : 'resultId'
          } found in SearchResults`,
        ),
      );
      return;
    }
    addSearchResultFeedback({
      queryId: resultQueryId,
      resultId,
    })
      .unwrap()
      .catch(err => {
        const errorDetails = {
          error: JSON.stringify(err),
          query: query,
          queryId: resultQueryId,
          resultId: resultId,
        };
        Bugsnag.notify(
          new Error(
            `Error in addSearchResultFeedback: ${JSON.stringify(errorDetails)}`,
          ),
        );
      });
  };

  const goToDetailsPage = id => {
    history.push(`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${id}`);
  };

  const onSearchCardClick = (id, resultId, documentId) => {
    dispatch(setId(id));
    dispatch(setData(result.data));
    onSearchResultFeedback({
      resultQueryId: result.queryId,
      resultId,
    });
    goToDetailsPage(documentId);
  };

  const getFeedbackText = (id: any) => {
    const thanksEl = color => (
      <span style={{ color }}>{t('Search.submitFeedback')}</span>
    );
    if (activeThumbsUp.includes(id)) {
      return thanksEl(theme.tertiaryPalette.green);
    } else if (activeThumbsDown.includes(id)) {
      return thanksEl(theme.secondaryPalette.red);
    } else {
      return <span>{t('Search.rateResult')}:</span>;
    }
  };

  const onThumbsUp = (id, resultId) => {
    setActiveThumbsUp([...activeThumbsUp, id]);
    setActiveThumbsDown(activeThumbsDown.filter(item => id !== item));
    onSearchResultFeedback({
      resultQueryId: result.queryId,
      resultId,
    });
  };
  const onThumbsDown = (id, resultId) => {
    setActiveThumbsUp(activeThumbsUp.filter(item => id !== item));
    setActiveThumbsDown([...activeThumbsDown, id]);
    onSearchResultFeedback({
      resultQueryId: result.queryId,
      resultId,
    });
  };

  const onSearchMisspellingSuggestionClick = suggestion => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`,
      search: `query=${suggestion}`,
    });
  };

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  const onLoadMore = () => {
    dispatch(setId(''));
    dispatch(setData({}));
    dispatch(setPageNumber(position.pageNumber + 1));
    fetchNextPage(position.pageNumber + 1);
  };

  return (
    <SearchResultsContainer>
      <LoadingOverlay
        isOpen={!!isFetching && !((searchResults?.length ?? 0) > 0)}
      />
      <DocumentHead pageTitle={query} />
      {(showingResults || misspellingSuggestion) && !isFetching ? (
        <>
          {misspellingSuggestion && (
            <StyledMisspellingSuggestionWrapper data-testid="MisspellingSuggestionWrapper">
              {t('Search.showingResultsSuggestion')}
              {': '}
              <StyledMisspellingSuggestion
                data-testid="MisspellingSuggestion"
                onClick={() =>
                  onSearchMisspellingSuggestionClick(misspellingSuggestion)
                }
              >
                {misspellingSuggestion}
              </StyledMisspellingSuggestion>
            </StyledMisspellingSuggestionWrapper>
          )}
          <Typography variant="body1">
            {numFound} {t('Search.numberOfResultsFor')} <b>{query}</b>
          </Typography>
        </>
      ) : (
        <Typography variant="body1">
          {numFound} {t('Search.numberOfResultsFor')} <b>{query}</b>
        </Typography>
      )}
      <div>
        {!searchResults?.length ? (
          <StyledNoResults>{t('Search.noResults')}</StyledNoResults>
        ) : (
          searchResults?.map(
            ({
              icon,
              id,
              documentId,
              locationTypes,
              marketTest,
              name,
              resultId,
              searchId,
              tags,
              type,
            }) => {
              // marketTest prop
              const oldIsMarketTest = marketTest;
              const xpApiIsMarketTest = tags?.includes('TEST'); //to become a const once you have a list (ask revamp?)
              const isMarketTest = xpApiFeatureFlag
                ? xpApiIsMarketTest
                : oldIsMarketTest;

              // locationType prop
              const oldLocationTypes = locationTypes ?? [];
              const xpApiLocationTypes = tags;
              const newLocationTypes = xpApiFeatureFlag
                ? xpApiLocationTypes?.filter(
                    tag => tag === 'LBM' || tag === 'STC',
                  )
                : oldLocationTypes;

              // resultId prop
              const oldResultId = resultId;
              const xpApiResultId = searchId;
              const newResultId = xpApiFeatureFlag
                ? xpApiResultId
                : oldResultId;

              return (
                <SearchResultCard
                  documentId={documentId || id}
                  documentType={type}
                  featureFlagSearchResultFeedback={
                    featureFlagSearchResultFeedback
                  }
                  getFeedbackText={getFeedbackText}
                  icon={icon ?? 'placeholder'}
                  id={id}
                  isMarketTest={isMarketTest}
                  isThumbsDown={activeThumbsDown.includes(id)}
                  isThumbsUp={activeThumbsUp.includes(id)}
                  key={id}
                  locationTypes={newLocationTypes}
                  name={name}
                  onClick={onSearchCardClick}
                  onThumbsDown={onThumbsDown}
                  onThumbsUp={onThumbsUp}
                  resultId={newResultId}
                />
              );
            },
          )
        )}
      </div>
      {result && hasNextPage && (
        <LoadMoreButton
          color="secondary"
          data-testid="LoadMore"
          disabled={isFetching}
          onClick={onLoadMore}
          size="lg"
          variant="filled"
        >
          <>
            {(xpAPIIsFetching || coreIsFetching) && (
              <LoadMoreLoadingIndicator disabled size="sm" variant="inline" />
            )}
            {t('Button.loadMore')}
          </>
        </LoadMoreButton>
      )}
      {showingResults && (
        <StyledShowingResultsFooter variant="body1">
          {t('Search.showingResultsFooter', {
            numberOfResults: numFound <= 100 ? numFound : '100+',
            resultsText: pluralize(
              numFound,
              t('Search.resultText'),
              t('Search.resultsText'),
            ),
            showingResults: showingResults,
          })}
        </StyledShowingResultsFooter>
      )}
      <SpacerDiv />
    </SearchResultsContainer>
  );
};

const SearchResultsContainer = styled.div`
  position: relative;
`;

const LoadMoreButton = styled(Button)`
  margin: 0 auto;
  margin-top: 32px;
  display: block;
`;
const LoadMoreLoadingIndicator = styled(LoadingIndicator)`
  margin-right: 10px;
  margin-top: -5px;
`;
const StyledMisspellingSuggestionWrapper = styled.span`
  display: block;
  font-weight: normal;
  color: ${props => props.theme.grayScale.gray7};
  margin-bottom: 15px;
`;
const StyledMisspellingSuggestion = styled.strong`
  color: ${props => props.theme.primaryPalette.navyBlue};
  font-weight: 700;
  cursor: pointer;
`;
const StyledShowingResultsFooter = styled(Typography)`
  text-align: center;
  margin-top: 16px;
  color: ${props => props.theme.grayScale.gray6};
`;
const StyledNoResults = styled.div`
  padding: 30px 20px;
`;
const SpacerDiv = styled.div`
  height: 400px;
`;

export default SearchResultsPage;
